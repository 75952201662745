import { StripeEnvironment } from '@libs/apps-shared/custom-types';

export const stripe: StripeEnvironment = {
  price_1LUgkBCoLr3qMb0HFdAUbdbl: {
    PRICE: '$9.99',
    FREQUENCY: 'annual',
    ACTIVE: true,
  },
  price_1LUgkOCoLr3qMb0HoX4HjUza: {
    PRICE: '$0.99',
    FREQUENCY: 'monthly',
    ACTIVE: true,
  },
  price_1LJeWZCoLr3qMb0HGgqKo8Sj: {
    PRICE: '$49.99',
    FREQUENCY: 'annual',
    ACTIVE: false,
  },
  price_1KYhvoCoLr3qMb0HsbBO8s4G: {
    PRICE: '$4.99',
    FREQUENCY: 'monthly',
    ACTIVE: false,
  },
};
