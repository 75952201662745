import { App, AppInfo } from '@capacitor/app';
import { isPlatform } from '@ionic/core';
import {
  getPlatforms,
  IonApp,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  ActivityContextType,
  AuthContextType,
  GeneralContextType,
  MyLibraryContextType,
  ScanningContextType,
  useActivity,
  useAuth,
  useGeneral,
  useMyLibrary,
  useScanning,
} from '@libs/apps-shared/contexts';
import { UserbaseError } from '@libs/apps-shared/custom-types';
import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Scope } from '@sentry/types';
import classNames from 'classnames';
import { createBrowserHistory } from 'history';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useMemo } from 'react';
import { matchPath, Redirect, Route } from 'react-router-dom';
import packageJSON from '../package.json';
import Tabs from './components/layout/Tabs';
import PrivateRoute from './components/navigation/PrivateRoute';
import PublicRoute from './components/navigation/PublicRoute';
import { environment } from './environments/environment';
import ForgotPassword from './pages/auth/ForgotPassword';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';

const history = createBrowserHistory();
// Make sure the order of the routes is correct. The longest url under the same parent should be placed first and in decreasing order.
const routes = [{ path: '/app/library/details/:isbn' }, { path: '/app/lending/:itemId' }];

/**
 * Cleans the route of any isbns or item ids.
 * @param {string} route
 * @returns {string}
 */
const scrubRoute = (route: string): string => {
  if (route.includes('/app/library/details/')) {
    return route.replace(/\/app\/library\/details\/.*/, '/app/library/details/:isbn');
  }
  if (route.includes('/app/lending/')) {
    return route.replace(/\/app\/lending\/.*/, '/app/lending/:itemId');
  }
  return route;
};

/**
 * Initializes sentry.
 * @param {string} release
 */
const initSentry = (release: string, dist?: string): void => {
  const isMobile: boolean = !platforms.includes('desktop') && !platforms.includes('mobileweb');
  if (!environment.SENTRY_ENABLED) return;
  Sentry.init(
    {
      dsn: 'https://e3d8c6a63a3a44a89c68970eceae66f9@o1284086.ingest.sentry.io/6494715',
      debug: !environment.production,
      enableNative: isMobile,
      enableNativeCrashHandling: isMobile,
      release: 'bookshelftracker@' + release,
      dist,
      environment: environment.environment,
      integrations: [
        new BrowserTracing({
          tracingOrigins: [environment.BOOKS_BASE_URL.replace(/http:\/\/|https:\/\//, '')],
          routingInstrumentation: SentrySibling.reactRouterV5Instrumentation(
            history,
            routes,
            matchPath
          ),
          shouldCreateSpanForRequest: (url) => {
            return !url.match(/\/api\/books/);
          },
        }),
      ],
      initialScope: {
        user: {
          id: '',
          ip_address: '',
        },
      },
      tracesSampleRate: environment.production ? 0.33 : 1.0,
      beforeSend: (event: SentrySibling.Event) => {
        if (event.user) {
          delete event.user.email;
          delete event.user.ip_address;
        }
        if (event.request) {
          if (
            event.request.url &&
            (event.request.url.includes('/app/library/details/') ||
              event.request.url.includes('/app/lending/'))
          ) {
            event.request.url = scrubRoute(event.request.url);
          }
          if (
            event.request.headers?.Referer &&
            (event.request.headers?.Referer.includes('/app/library/details/') ||
              event.request.headers?.Referer.includes('/app/lending/'))
          ) {
            event.request.headers.Referer = scrubRoute(event.request.headers.Referer);
          }
        }
        return event;
      },
      beforeBreadcrumb: (breadcrumb: SentrySibling.Breadcrumb) => {
        if (breadcrumb.category === 'navigation') {
          if (
            breadcrumb.data?.from &&
            (breadcrumb.data?.from.includes('/app/library/details/') ||
              breadcrumb.data?.from.includes('/app/lending/'))
          ) {
            breadcrumb.data.from = scrubRoute(breadcrumb.data.from);
          }
          if (
            breadcrumb.data?.to &&
            (breadcrumb.data?.to.includes('/app/library/details/') ||
              breadcrumb.data?.to.includes('/app/lending/'))
          ) {
            breadcrumb.data.to = scrubRoute(breadcrumb.data.to);
          }
        }
        if (
          breadcrumb.type === 'http' &&
          !!breadcrumb.data?.url &&
          breadcrumb.data.url.includes('/api/books')
        ) {
          let data = Object.assign({}, breadcrumb.data);
          if (data.url.includes('/api/books/cover?')) {
            data.url = data.url.replace(/\/api\/books\/cover?.*/, '/api/books/cover?url=:url');
          } else if (data.url.includes('/api/books/search')) {
            data.url = data.url.replace(
              /\/api\/books\/search.*/,
              '/api/books/search?searchTerm=:searchTerm'
            );
          } else if (data.url.includes('/api/books?')) {
            data.url = data.url.replace(/\/api\/books\/?.*/, '/api/books?isbns=:isbns');
          } else if (data.url.includes('/api/books/')) {
            data.url = data.url.replace(/\/api\/books\/.*/, '/api/books/:isbn');
          }
          return Object.assign(
            {},
            {
              ...breadcrumb,
              data,
            }
          );
        }
        return breadcrumb;
      },
    },
    // Forward the init method to the sibling Framework.
    SentrySibling.init
  );
};

const platforms = getPlatforms();
const isMobile: boolean = !platforms.includes('desktop') && !platforms.includes('mobileweb');
const version = packageJSON.version || 'unknown';
if (isMobile) {
  App.getInfo()
    .then((appInfo: AppInfo) => {
      if (appInfo.build && appInfo.version && isMobile && isPlatform('android')) {
        initSentry(appInfo.version, appInfo.build);
      } else {
        initSentry(version);
      }
    })
    .catch((error) => {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Navigation.AppVersion.getVersionCode');
        return scope;
      });
      console.error('Error getting version number to initialize sentry', error);
      initSentry(version);
    });
} else {
  initSentry(version);
}

const Navigation = () => {
  const { loading }: GeneralContextType = useGeneral();
  const { initialized, currentUser }: AuthContextType = useAuth();
  const { getMyLibrary }: MyLibraryContextType = useMyLibrary();
  const { getActivity }: ActivityContextType = useActivity();
  const { scanning, closeScanner }: ScanningContextType = useScanning();

  useEffect(() => {
    if (initialized && !!currentUser) {
      (async () => {
        let errors: string[] = [];
        try {
          await getMyLibrary();
        } catch (error) {
          const err: UserbaseError = error as UserbaseError;
          errors.push(err.message);
        }
        try {
          await getActivity();
        } catch (error) {
          const err: UserbaseError = error as UserbaseError;
          errors.push(err.message);
        }
        if (errors.length > 0) {
          alert(errors.join('\n'));
        }
      })();
    }
  }, [initialized, currentUser]);

  return useMemo(
    () => (
      <IonApp>
        <div className={classNames({ hidden: scanning })}>
          {initialized && (
            <IonReactRouter history={history}>
              <IonRouterOutlet id="main">
                <PublicRoute path="/sign-in" component={SignIn} />
                <PublicRoute path="/forgot-password" component={ForgotPassword} />
                <PublicRoute path="/sign-up" component={SignUp} />
                <Route exact path="/" render={() => <Redirect to="/sign-in" />} />
                <PrivateRoute path="/app" component={Tabs} />
              </IonRouterOutlet>
            </IonReactRouter>
          )}
        </div>
        <IonLoading isOpen={loading} mode="ios" spinner="crescent" />
        {scanning && (
          <>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Scanning</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={closeScanner}>
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="scanner-content">
              <div className="absolute inset-0 flex flex-col">
                <div className="bg-black bg-opacity-50 flex-1"></div>
                <div className="flex flex-row">
                  <div className="bg-black bg-opacity-50 flex-1"></div>
                  <div className="w-4/5">
                    <div className="flex justify-between items-center relative">
                      <div className="absolute -top-1 -left-1 w-8 h-8 border-4 border-white  border-r-0 border-b-0"></div>
                      <div className="absolute -top-1 -right-1 w-8 h-8 border-4 border-white  border-b-0 border-l-0"></div>
                    </div>
                    <div className="w-full h-36"></div>
                    <div className="flex justify-between items-center relative">
                      <div className="absolute -bottom-1 -left-1 w-8 h-8 border-4 border-white  border-t-0 border-r-0"></div>
                      <div className="absolute -bottom-1 -right-1 w-8 h-8 border-4 border-white  border-t-0 border-l-0"></div>
                    </div>
                  </div>
                  <div className="bg-black bg-opacity-50 flex-1"></div>
                </div>
                <div className="bg-black bg-opacity-50 flex-1"></div>
              </div>
            </IonContent>
          </>
        )}
      </IonApp>
    ),
    [initialized, scanning, loading]
  );
};

export default Navigation;
