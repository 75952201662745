import { SearchbarChangeEventDetail } from '@ionic/core';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  ActivityContextType,
  AuthContextType,
  BooksContextType,
  useActivity,
  useAuth,
  useBooks,
} from '@libs/apps-shared/contexts';
import { ActivityItem } from '@libs/apps-shared/custom-types';
import { Book } from '@mylibrary/api-types';
import { useEffect, useState } from 'react';
import ActivityList from '../../components/lists/activity/ActivityList';
import ManageSubscriptionOverlay from '../../components/payments/ManageSubscriptionOverlay';
import TrialToolbar from '../../components/payments/TrialToolbar';
import FilterPopover, { FilterOption } from '../../components/search-filter-sort/FilterPopover';
import SortPopover, { SortOptionKeys } from '../../components/search-filter-sort/SortPopover';
import { compareDates, getISODate } from '../../utils/dates';

const Activity = () => {
  const { currentUser, subscriptionInfo }: AuthContextType = useAuth();
  const { activity, isFetchingActivity }: ActivityContextType = useActivity();
  const { cachedBooks, getBooks }: BooksContextType = useBooks();
  const [getBooksError, setGetBooksError] = useState<Error>();
  const [filteredActivity, setFilteredActivity] = useState<ActivityItem[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeSort, setActiveSort] = useState<SortOptionKeys>('date-desc');
  const [filterOptions] = useState<FilterOption[]>([
    {
      key: 'returned',
      label: 'Returned',
      initiallySelected: true,
      color: 'success',
    },
    {
      key: 'overdue',
      label: 'Overdue',
      initiallySelected: true,
      color: 'danger',
    },
    {
      key: 'dueSoon',
      label: 'Due Soon',
      initiallySelected: true,
      color: 'warning',
    },
    {
      key: 'dueLater',
      label: 'Due Later',
      initiallySelected: true,
      color: 'primary',
    },
  ]);
  const [activeFilters, setActiveFilters] = useState<string[]>([
    'returned',
    'overdue',
    'dueSoon',
    'dueLater',
  ]);

  /**
   * Loads more activity into view on scroll.
   * @param {CustomEvent<Void>} event
   */
  const loadMoreActivity = (event: CustomEvent<void>): void => {
    let numberToAdd: number = 10;
    if (itemsToShow + 10 > filteredActivity.length) {
      numberToAdd = filteredActivity.length - itemsToShow;
    }
    setItemsToShow(itemsToShow + numberToAdd);
    // Unfortunately this type is not correct, so have to ignore.
    //@ts-ignore
    event.target.complete();
  };

  useEffect(() => {
    let activityToSearchFilterSort: ActivityItem[] = [];
    if (searchTerm) {
      activityToSearchFilterSort = [
        ...activity.filter(({ item }: ActivityItem) => {
          const book: Book = cachedBooks[item.book.isbn];
          return (
            book.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.borrower.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.borrower.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.borrower.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.notes?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.lender.username.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }),
      ];
    } else {
      activityToSearchFilterSort = [...activity];
    }
    if (activeFilters.length !== filterOptions.length) {
      activityToSearchFilterSort = activityToSearchFilterSort.filter(({ item }: ActivityItem) => {
        if (!!item.dateReturned) {
          if (activeFilters.includes('returned')) {
            return true;
          }
          return false;
        }
        const nowISO: string = getISODate(new Date());
        const dueDateISO: string = getISODate(item.dueDate);
        if (activeFilters.includes('overdue') && nowISO > dueDateISO) return true;
        const weekBeforeDueDate: Date = new Date(item.dueDate);
        weekBeforeDueDate.setDate(weekBeforeDueDate.getDate() - 7);
        const weekBeforeDueDateISO: string = getISODate(weekBeforeDueDate);
        if (
          activeFilters.includes('dueSoon') &&
          nowISO >= weekBeforeDueDateISO &&
          nowISO < dueDateISO
        )
          return true;
        if (activeFilters.includes('dueLater') && nowISO < weekBeforeDueDateISO) return true;
        return false;
      });
    }
    if (activeSort === 'date-desc') {
      activityToSearchFilterSort.sort((item1: ActivityItem, item2: ActivityItem) =>
        compareDates(
          !!item2.item.dateReturned ? item2.item.dateReturned : item2.item.borrowDate,
          !!item1.item.dateReturned ? item1.item.dateReturned : item1.item.borrowDate
        )
      );
    } else if (activeSort === 'date-asc') {
      activityToSearchFilterSort.sort((item1: ActivityItem, item2: ActivityItem) =>
        compareDates(
          !!item1.item.dateReturned ? item1.item.dateReturned : item1.item.borrowDate,
          !!item2.item.dateReturned ? item2.item.dateReturned : item2.item.borrowDate
        )
      );
    }
    setFilteredActivity([...activityToSearchFilterSort]);
  }, [searchTerm, activeSort, activeFilters, activity]);

  useEffect(() => {
    (async () => {
      if (!!currentUser && activity.length > 0) {
        try {
          await getBooks(
            activity.slice(0, itemsToShow).map((a: ActivityItem) => {
              return a.item.book.isbn;
            }),
            currentUser.authToken
          );
        } catch (error) {
          setGetBooksError(error as Error);
        }
      }
    })();
  }, [currentUser, activity, itemsToShow]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Lending</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchTerm}
            onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>) =>
              setSearchTerm(e.detail.value || '')
            }
            showClearButton="always"
            disabled={subscriptionInfo?.disableAppUse}
          />
          <IonButtons slot="end">
            <FilterPopover
              filterOptions={filterOptions}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
            />
            <SortPopover
              sortOptions={['date-asc', 'date-desc']}
              activeSort={activeSort}
              setActiveSort={setActiveSort}
            />
          </IonButtons>
        </IonToolbar>
        <TrialToolbar />
      </IonHeader>
      <IonContent scrollY={!!subscriptionInfo ? !subscriptionInfo.disableAppUse : true}>
        <ManageSubscriptionOverlay />
        <ActivityList
          books={cachedBooks}
          getBooksError={getBooksError}
          activity={filteredActivity.slice(0, itemsToShow)}
          isFetchingActivity={isFetchingActivity}
          totalActivity={activity.length}
          loadMoreActivity={loadMoreActivity}
        />
      </IonContent>
    </IonPage>
  );
};

export default Activity;
