import {
  InfiniteScrollCustomEvent,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  SelectCustomEvent,
  isPlatform,
} from '@ionic/react';
import { GetBestSellers, GetBookStats, GetFeaturedBooks, SearchBooks } from '@libs/apps-shared/api';
import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import {
  BestSellersListOptions,
  Book,
  BookStats,
  SearchBooksBy,
  SearchBooksQuery,
  SearchBooksResponse,
} from '@mylibrary/api-types';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import { BooksAPI } from '../../api/apiKit';
import MyLibraryGrid from '../../components/grids/library/MyLibraryGrid';
import ManageSubscriptionOverlay from '../../components/payments/ManageSubscriptionOverlay';
import TrialToolbar from '../../components/payments/TrialToolbar';

const DEFAULT_NUMBER_TO_ADD: number = isPlatform('desktop') ? 25 : 15;

const Search = () => {
  const { subscriptionInfo, currentUser }: AuthContextType = useAuth();
  const [bookStats, setBookStats] = useState<BookStats>();
  const [featuredBooks, setFeaturedBooks] = useState<Book[]>([]);
  const [nonFictionBooks, setNonFictionBooks] = useState<Book[]>([]);
  const [fictionBooks, setFictionBooks] = useState<Book[]>([]);
  // Search variables.
  const [searchParams, setSearchParams] = useState<SearchBooksQuery>({
    page: 1,
    pageSize: DEFAULT_NUMBER_TO_ADD,
    searchTerm: '',
    searchType: SearchBooksBy.Title,
  });
  const [searchResults, setSearchResults] = useState<SearchBooksResponse | undefined>();
  const [booksToShow, setBooksToShow] = useState<Book[]>([]);

  /**
   * Gets book stats.
   * @param {string} authToken
   */
  const getBookStats = async (authToken: string): Promise<void> => {
    try {
      const res: BookStats = await GetBookStats(BooksAPI, authToken);
      setBookStats(res);
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Search.getBookStats');
        return scope;
      });
      console.error('Error loading book stats...');
    }
  };

  /**
   * Gets featured books.
   * @param {string} authToken
   */
  const getFeaturedBooks = async (authToken: string): Promise<void> => {
    try {
      const res: Book[] = await GetFeaturedBooks(BooksAPI, authToken);
      setFeaturedBooks(res);
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Search.getFeaturedBooks');
        return scope;
      });
      console.error('Error loading featured books...');
    }
  };

  /**
   * Gets best selling books.
   * @param {string} authToken
   * @param {BestSellersListOptions} bestSellerType
   */
  const getBestSellers = async (
    authToken: string,
    bestSellerType: BestSellersListOptions
  ): Promise<void> => {
    try {
      const res: Book[] = await GetBestSellers(BooksAPI, authToken, bestSellerType);
      if (bestSellerType === BestSellersListOptions.Fiction) {
        setFictionBooks(res);
      } else if (bestSellerType === BestSellersListOptions.NonFiction) {
        setNonFictionBooks(res);
      }
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Search.getBestSellers');
        return scope;
      });
      console.error('Error loading books from search...');
    }
  };

  /**
   * Search books.
   * @param {string} authToken
   * @param {'new' | 'add'} [searchType="new"]
   * @param {SearchBooksQuery} searchQuery
   * @param {() => void} [callback]
   */
  const searchBooks = async (
    authToken: string,
    searchType: 'new' | 'add' = 'new',
    searchQuery: SearchBooksQuery,
    callback?: () => void
  ): Promise<void> => {
    try {
      const res: SearchBooksResponse = await SearchBooks(BooksAPI, authToken, searchQuery);
      setSearchResults(res);
      if (searchType === 'new') setBooksToShow(res.data);
      else setBooksToShow([...booksToShow].concat(res.data));
    } catch (error) {
      Sentry.captureException(error, (scope: Scope) => {
        scope.setTag('function', 'Search.searchBooks');
        return scope;
      });
      console.error('Error loading books from search...');
    } finally {
      if (callback) callback();
      setSearchParams(searchQuery);
    }
  };

  /**
   * Clear search results.
   */
  const clearSearch = () => {
    setSearchParams({
      page: 1,
      pageSize: DEFAULT_NUMBER_TO_ADD,
      searchTerm: '',
      searchType: SearchBooksBy.Title,
    });
    setSearchResults(undefined);
    setBooksToShow([]);
  };

  /**
   * Loads more search results.
   * @param {InfiniteScrollCustomEvent} event
   */
  const loadMoreSearchResults = (event: InfiniteScrollCustomEvent): void => {
    if (searchResults) {
      const maxPages = Math.ceil(searchResults.total / DEFAULT_NUMBER_TO_ADD);
      if (searchParams.page < maxPages) {
        if (currentUser) {
          searchBooks(
            currentUser.authToken,
            'add',
            {
              ...searchParams,
              page: searchParams.page + 1,
            },
            () => event.target.complete()
          );
        }
      }
    }
  };

  /**
   * Triggers search on return/enter key press.
   * @param {KeyboardEvent<HTMLIonSearchbarElement>} event
   */
  const onSearchBarKeyUp = (event: KeyboardEvent<HTMLIonSearchbarElement>): void => {
    if (event.key === 'Enter') {
      if (
        currentUser?.authToken &&
        event.currentTarget.value &&
        event.currentTarget.value !== searchParams.searchTerm
      ) {
        searchBooks(currentUser.authToken, 'new', {
          ...searchParams,
          searchTerm: event.currentTarget.value,
        });
      }
    }
  };

  /**
   * Handles when the search bar gets blurred.
   * @param {FocusEvent<HTMLIonSearchbarElement>} event
   */
  const onSearchBarBlur = (event: FocusEvent<HTMLIonSearchbarElement>): void => {
    if (!event.currentTarget.value) {
      clearSearch();
    }
  };

  /**
   * Searches for books when the category changes.
   * @param {SearchBooksBy} value
   */
  const onSelectSearchCategory = (value: SearchBooksBy): void => {
    if (currentUser?.authToken && !!searchParams.searchTerm) {
      searchBooks(currentUser.authToken, 'new', { ...searchParams, searchType: value });
    } else {
      setSearchParams({
        ...searchParams,
        searchType: value,
      });
    }
  };

  useEffect(() => {
    if (currentUser?.authToken) {
      getBookStats(currentUser.authToken);
      getFeaturedBooks(currentUser.authToken);
      getBestSellers(currentUser.authToken, BestSellersListOptions.Fiction);
      getBestSellers(currentUser.authToken, BestSellersListOptions.NonFiction);
    }
  }, [currentUser]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Search</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            animated={true}
            placeholder={
              searchParams.searchType === SearchBooksBy.Author
                ? bookStats?.authors
                  ? `Over ${Math.floor(
                      bookStats.authors / 1000000
                    ).toLocaleString()} million authors`
                  : ''
                : bookStats?.books
                ? `Over ${Math.floor(bookStats.books / 1000000).toLocaleString()} million books`
                : ''
            }
            onKeyUp={onSearchBarKeyUp}
            onBlur={onSearchBarBlur}
            onIonClear={clearSearch}
          />
          <div slot="end">
            <IonItem lines="none">
              <IonLabel position="stacked">Search by</IonLabel>
              <IonSelect
                value={searchParams.searchType}
                onIonChange={(e: SelectCustomEvent<SearchBooksBy>) =>
                  onSelectSearchCategory(e.detail.value)
                }
              >
                <IonSelectOption value={SearchBooksBy.Title}>Title</IonSelectOption>
                <IonSelectOption value={SearchBooksBy.Author}>Author</IonSelectOption>
                <IonSelectOption value={SearchBooksBy.ISBN}>ISBN</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
        </IonToolbar>
        <TrialToolbar />
      </IonHeader>
      <IonContent scrollY={!!subscriptionInfo ? !subscriptionInfo.disableAppUse : true}>
        <ManageSubscriptionOverlay />
        <div className="h-full p-4">
          {searchResults ? (
            booksToShow.length > 0 && searchResults.data.length > 0 ? (
              <>
                <MyLibraryGrid books={booksToShow} />
                <IonInfiniteScroll
                  onIonInfinite={loadMoreSearchResults}
                  threshold="100px"
                  disabled={booksToShow.length >= searchResults.total}
                  className="mt-4"
                >
                  <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Loading more books"
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
              </>
            ) : (
              <IonItem>
                <IonLabel>No books found</IonLabel>
              </IonItem>
            )
          ) : (
            <>
              {Object.keys(featuredBooks).length > 0 && (
                <>
                  <h2 className="font-bold mt-0">Featured</h2>
                  <MyLibraryGrid books={featuredBooks} />
                </>
              )}
              {(Object.keys(nonFictionBooks).length > 0 ||
                Object.keys(fictionBooks).length > 0) && (
                <>
                  <h2 className="font-bold">Best Sellers</h2>
                  {Object.keys(nonFictionBooks).length > 0 && (
                    <>
                      <h3 className="font-bold text-lg">Non-Fiction</h3>
                      <MyLibraryGrid books={nonFictionBooks} />
                    </>
                  )}
                  {Object.keys(fictionBooks).length > 0 && (
                    <>
                      <h3 className="font-bold text-lg">Fiction</h3>
                      <MyLibraryGrid books={fictionBooks} />
                    </>
                  )}
                  <p className="text-gray-400 text-xs text-center sm:text-left py-4">
                    Best Sellers Data provided by The New York Times.
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Search;
