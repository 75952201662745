import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import classNames from 'classnames';
import { addCircle, cog, library, search, swapHorizontal } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import ActivityDetails from '../../pages/activity/ActivityDetails';
import LendBook from '../../pages/activity/LendBook';
import BookDetails from '../../pages/books/BookDetails';
import ChangePassword from '../../pages/settings/ChangePassword';
import DangerZone from '../../pages/settings/DangerZone';
import ManageSubscription from '../../pages/settings/ManageSubscription';
import Onboarding from '../../pages/settings/Onboarding';
import Profile from '../../pages/settings/Profile';
import Activity from '../../pages/tabs/Activity';
import Library from '../../pages/tabs/Library';
import Search from '../../pages/tabs/Search';
import Settings from '../../pages/tabs/Settings';
import ActionSheet from '../ActionSheet';
import PrivateRoute from '../navigation/PrivateRoute';

const Tabs = () => {
  const { currentUser, subscriptionInfo }: AuthContextType = useAuth();
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const history = useHistory();
  const [hideTabBar, setHideTabBar] = useState<boolean>(false);

  /**
   * Show the action sheet on bottomSheet change.
   * @param {CustomEvent<{ tab: string }>} event
   */
  const handleTabsChange = (event: CustomEvent<{ tab: string }>) => {
    if (event.detail.tab === 'bottomSheet') {
      setShowActionSheet(true);
    }
  };

  useEffect(() => {
    const shouldHideTabBar = (pathname: string) => {
      if (
        !pathname.endsWith('/library') &&
        !pathname.endsWith('/search') &&
        !pathname.endsWith('/lending') &&
        !pathname.endsWith('/settings')
      ) {
        setHideTabBar(true);
      } else {
        setHideTabBar(false);
      }
    };

    shouldHideTabBar(history.location.pathname);

    const unlisten = history.listen((listener) => {
      const { pathname } = listener;
      shouldHideTabBar(pathname);
    });
    return unlisten;
  }, []);

  return (
    <>
      <IonTabs onIonTabsDidChange={handleTabsChange}>
        <IonRouterOutlet>
          <PrivateRoute path="/app/library" component={Library} exact={true} />
          <PrivateRoute path="/app/search" component={Search} exact={true} />
          <PrivateRoute path="/app/lending" component={Activity} exact={true} />
          <PrivateRoute path="/app/settings" component={Settings} exact={true} />
          {currentUser?.changePassword ? (
            <PrivateRoute path="/app/settings/change-password" component={ChangePassword} exact />
          ) : (
            <>
              <PrivateRoute path="/app/library/details/:isbn" component={BookDetails} exact />
              <PrivateRoute path="/app/library/lend-book" component={LendBook} exact />
              <PrivateRoute path="/app/library/lend-book/:itemId" component={LendBook} exact />
              <PrivateRoute path="/app/lending/:itemId" component={ActivityDetails} exact />
              <PrivateRoute path="/app/settings/profile" component={Profile} exact />
              <PrivateRoute path="/app/settings/change-password" component={ChangePassword} exact />
              <PrivateRoute path="/app/settings/danger-zone" component={DangerZone} exact />
              <PrivateRoute path="/app/settings/onboarding" component={Onboarding} exact />
              <PrivateRoute
                path="/app/settings/manage-subscription"
                component={ManageSubscription}
                exact
              />
            </>
          )}
          <Route path="/app" render={() => <Redirect to="/app/library" />} exact={true} />
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          className={classNames('border-t dark:border-0', { hidden: hideTabBar })}
        >
          <IonTabButton tab="library" href="/app/library">
            <IonIcon icon={library} className="text-2xl" />
            <IonLabel className="font-semibold">Library</IonLabel>
          </IonTabButton>
          <IonTabButton tab="search" href="/app/search">
            <IonIcon icon={search} className="text-2xl" />
            <IonLabel className="font-semibold">Search</IonLabel>
          </IonTabButton>
          <IonTabButton tab="bottomSheet" disabled={subscriptionInfo?.disableAppUse}>
            <IonIcon color="primary" icon={addCircle} className="text-8xl" />
          </IonTabButton>
          <IonTabButton tab="lending" href="/app/lending">
            <IonIcon icon={swapHorizontal} className="text-2xl" />
            <IonLabel className="font-semibold">Lending</IonLabel>
          </IonTabButton>
          <IonTabButton tab="settings" href="/app/settings">
            <IonIcon icon={cog} className="text-2xl" />
            <IonLabel className="font-semibold">Settings</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <ActionSheet
        showActionSheet={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
      />
    </>
  );
};

export default Tabs;
