export const environment = {
  production: true,
  environment: 'production',
  SENTRY_ENABLED: true,
  PUBLIC_APP_URL: 'https://app.bookshelftracker.com',
  PUBLIC_WEBSITE_URL: 'https://bookshelftracker.com',
  ACTIVITY_DATABASE_NAME: 'activity_45262479',
  MY_LIBRARY_DATABASE_NAME: 'my_library_28965563',
  USERBASE_APP_ID: '9d5e8363-2478-4c79-9eb6-e52242dba882',
  BOOKS_BASE_URL: 'https://api.bookshelftracker.com/api',
  REVENUE_CAT_SECRET_APPLE: 'appl_mRztpobkCuSSkifKZqJOAmuzupt',
  REVENUE_CAT_SECRET_GOOGLE: 'goog_bBPHxxkZJrSblvaBKaPlcdvKfjt',
};
