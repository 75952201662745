import { App, AppInfo } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { getPlatforms } from '@ionic/core';
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { AuthContextType, useAuth } from '@libs/apps-shared/contexts';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/types';
import { History } from 'history';
import {
  cardOutline,
  documentLockOutline,
  documentTextOutline,
  helpCircleOutline,
  lockClosedOutline,
  logOutOutline,
  mailOutline,
  personCircleOutline,
  removeOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import packageJSON from '../../../package.json';
import TrialToolbar from '../../components/payments/TrialToolbar';
import { environment } from '../../environments/environment';

interface Props {
  history: History;
}
const Settings = ({ history }: Props) => {
  const { signOut }: AuthContextType = useAuth();
  const [versionCode, setVersionCode] = useState<string | number>();
  const [versionNumber, setVersionNumber] = useState<string | number>();

  const openUrl = (url: string) => {
    Browser.open({
      url,
      windowName: '_blank',
    });
  };

  useEffect(() => {
    const platforms = getPlatforms();
    const isMobile: boolean = !platforms.includes('desktop') && !platforms.includes('mobileweb');
    if (isMobile) {
      App.getInfo()
        .then(({ version, build }: AppInfo) => {
          setVersionNumber(version);
          setVersionCode(build);
        })
        .catch((error) => {
          Sentry.captureException(error, (scope: Scope) => {
            scope.setTag('function', 'Setting.useEffect.App.getInfo');
            return scope;
          });
        });
    } else {
      setVersionNumber(packageJSON.version);
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
        <TrialToolbar />
      </IonHeader>
      <IonContent className="settings-navigation-item">
        <IonListHeader>
          <IonLabel>Account</IonLabel>
        </IonListHeader>
        <IonList>
          <IonItem button detail lines="full" onClick={() => history.push('/app/settings/profile')}>
            <IonIcon icon={personCircleOutline} slot="start" />
            <IonLabel>Profile</IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            onClick={() => history.push('/app/settings/change-password')}
          >
            <IonIcon icon={lockClosedOutline} slot="start" />
            <IonLabel>Change Password</IonLabel>
          </IonItem>
          <IonItem
            id="manage-subscription-item"
            button
            detail
            lines="full"
            onClick={() => history.push('/app/settings/manage-subscription')}
          >
            <IonIcon icon={cardOutline} slot="start" />
            <IonLabel>Manage Subscription</IonLabel>
          </IonItem>
          <IonItem
            id="danger-zone-item"
            button
            detail
            lines="full"
            onClick={() => history.push('/app/settings/danger-zone')}
          >
            <IonIcon icon={removeOutline} slot="start" />
            <IonLabel>Danger Zone</IonLabel>
          </IonItem>
          <IonItem button detail={false} lines="full" onClick={signOut}>
            <IonIcon icon={logOutOutline} slot="start" />
            <IonLabel>Sign Out</IonLabel>
          </IonItem>
        </IonList>
        <IonListHeader>
          <IonLabel>BookshelfTracker</IonLabel>
        </IonListHeader>
        <IonList>
          <IonItem
            button
            detail
            lines="full"
            onClick={() => history.push('/app/settings/onboarding')}
          >
            <IonIcon icon={helpCircleOutline} slot="start" />
            <IonLabel>Onboarding Screens</IonLabel>
          </IonItem>
          <IonItem button detail lines="full" href="mailto:contact@bookshelftracker.com">
            <IonIcon icon={mailOutline} slot="start" />
            <IonLabel>Contact Us</IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            onClick={() => openUrl(`${environment.PUBLIC_WEBSITE_URL}/privacy-policy`)}
          >
            <IonIcon icon={documentLockOutline} slot="start" />
            <IonLabel>Privacy Policy</IonLabel>
          </IonItem>
          <IonItem
            button
            detail
            lines="full"
            onClick={() => openUrl(`${environment.PUBLIC_WEBSITE_URL}/terms-and-conditions`)}
          >
            <IonIcon icon={documentTextOutline} slot="start" />
            <IonLabel>Terms and Conditions</IonLabel>
          </IonItem>
        </IonList>
        {versionNumber && (
          <p className="text-center py-4">
            {versionNumber}
            {versionCode ? ` (${versionCode})` : ''}
          </p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Settings;
